:root {
  --white: #f5f5f5;
  --mint: #d5f3ed;
  --black: #1e1e1e;
  --blue: #d5eaf3;
  --green: #d5f3de;
  --purple: #edd5f3;
  --tan: #f3edd5;
  --red: #f3d5db;
  --dark-blue: #a5baf3;
  --dark-green: #a5f3ae;
  --dark-purple: #bda5f3;
  --dark-tan: #f3bda5;
  --dark-red: #f3858b;
}

.App-bg {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  z-index: -1;
}
.App-content {
  align-items: center;
  justify-content: center;
  margin: auto;
}

.Header {
  background-color: var(--mint);
  color: var(--black);
}
.Header-content {
  margin: auto;
  text-align: center;
  align-items: center;
}
.Header-logo {
  min-width: 90px;
  display: block;
  margin: auto;
  width: 50%;
}
.Header-logo-container {
  width: 100%;
  margin: auto;
}
.Header-title {
  text-align: center;
  min-width: 80px;
  margin: auto;
}
.Header-links {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.Header-link {
  color: var(--black);
}

.Tool-Header-text {
  margin: auto;
  text-align: center;
}

.Interest-Calculator-forms-container {
  margin: auto;
  min-width: 300px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Interest-Calculator-form-wrapper {
  margin: auto;
}
.Interest-Calculator-forms {
  margin: auto;
  min-width: 200px;
}
.Interest-Calculator-chart-container {
  margin: auto;
  min-width: 300px;
  min-height: 300px;
}
.Interest-Calculator-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
}
.Interest-Calculator-button {
  text-align: center;
}
.Interest-Calculator-formula-info {
  min-width: 300px;
  margin: auto;
  padding-top: 4vh;
}
.tool-amount-display {
  color: var(--dark-blue);
  margin: auto;
  text-align: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .App-content {
    width: 90vw;
    padding-bottom: 8vh;
  }

  .Header-content {
    width: 90vw;
    padding: 2vh;
  }
  .Header-logo {
    width: 15vh;
  }
  .Header-title {
    display: none;
  }
  .Header-link {
    display: none;
  }

  .Tool-Header-text {
    padding: 3vh;
    font-size: 200%;
    font-weight: 500;
  }
  .Interest-Calculator-button-container {
    padding: 2vh;
  }
  .Interest-Calculator-button {
    padding: 1vh;
  }
}

@media only screen and (min-width: 768px) {
  .App-content {
    min-width: 300px;
    width: 80vw;
    padding-bottom: 10vh;
  }

  .Header-content {
    width: clamp(300px, 75vw, 1800px);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 2vw;
  }
  .Header-logo {
    max-width: 300px;
    width: 14vw;
  }
  .Header-title {
    font-family: "DM Sans", sans-serif;
    font-size: calc(20px + 2rem);
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .Header-link {
    font-size: calc(4px + 1rem);
    padding-left: 2vw;
  }

  .Tool-Header-text {
    padding: 3vh;
    font-size: 200%;
    font-weight: 500;
  }
  .Interest-Calculator-forms-container {
    width: 80vw;
  }
  .Interest-Calculator-forms {
    padding: 2vw;
    width: 40%;
    max-width: 475px;
  }
  .Interest-Calculator-form-wrapper {
    width: 80%;
  }
  .Interest-Calculator-chart-container {
    width: 60vw;
    height: 60vh;
    max-width: 1600px;
    max-height: 1600px;
  }
  .Interest-Calculator-button-container {
    padding: 2vh;
  }
  .Interest-Calculator-formula-info {
    width: 40vw;
  }
  h3.tool-amount-display-header {
    padding-top: 4vh;
    font-size: 1.5em;
    width: 100%;
  }
  h1.tool-amount-display-amount {
    padding-top: 3vh;
    font-size: 3em;
    text-align: center;
  }
}
